<template>
    <div class="admin_page_body" v-loading="isRequestLoading">
        <el-form ref="form" :inline="true" :model="form" size="small" label-position="top">
            <div class="admin_page_item mt18 pr0 pb0">
                <h1 class="title">基本信息</h1>
                <div class="data_detail mt24">
                    <el-form-item label="公司名称">
                        <span class="text">{{infoData.corporateName}}</span>
                    </el-form-item>

                    <el-form-item label="公司所在地">
                        <span class="text">{{infoData.corporateProvince}} {{infoData.corporateCity}} {{infoData.corporateArea}}</span>
                    </el-form-item>

                    <el-form-item label="公司详细地址">
                        <span class="text">{{infoData.corporateAddress}}</span>
                    </el-form-item>

                    <el-form-item label="公司电话">
                        <span class="text">{{infoData.corporatePhone}}</span>
                    </el-form-item>

                    <el-form-item label="员工总数">
                        <span class="text">{{infoData.corporatePeopleNum}}人</span>
                    </el-form-item>

                    <el-form-item label="注册资金">
                        <span class="text">{{infoData.corporateRegisteredPrice}}万元</span>
                    </el-form-item>

                    <el-form-item label="联系人姓名">
                        <span class="text">{{infoData.corporateContactName}}</span>
                    </el-form-item>

                    <el-form-item label="联系人电话">
                        <span class="text">{{infoData.corporateContactPhone}}</span>
                    </el-form-item>

                    <el-form-item label="电子邮箱">
                        <span class="text">{{infoData.corporateContactMail}}</span>
                    </el-form-item>
                </div>
            </div>

            <div class="admin_page_item mt18 pr0 pb0">
                <h1 class="title">营业执照信息</h1>
                <div class="data_detail mt24">
                    <el-form-item label="营业执照号">
                        <span class="text">{{infoData.businessLicenseNo}}</span>
                    </el-form-item>

                    <el-form-item label="法定经营范围" class="full">
                        <span class="text">{{infoData.legalBusinessScope}}</span>
                    </el-form-item>

                    <el-form-item label="证件上传" class="full">
                        <el-image fit="contain" v-if="infoData.businessLicenseImage"
                                  style="width: 100px; height: 100px"
                                  :src="imageURL + infoData.businessLicenseImage"
                                  :preview-src-list="[imageURL + infoData.businessLicenseImage]">
                        </el-image>
                    </el-form-item>
                </div>
            </div>

            <div class="admin_page_item mt18 pr0 pb0">
                <h1 class="title">法人信息</h1>
                <div class="data_detail mt24">
                    <el-form-item label="法人姓名">
                        <span class="text">{{infoData.legalRepresentativeName}}</span>
                    </el-form-item>

                    <el-form-item label="法人证件号">
                        <span class="text">{{infoData.legalRepresentativeCardno}}</span>
                    </el-form-item>

                    <el-form-item label="证件有效期">
                        <span class="text" v-if="infoData.legalRepresentativeCardValidityLong === 1">长期有效</span>
                        <span class="text" v-else>{{infoData.legalRepresentativeCardValidity}}</span>
                    </el-form-item>

                    <el-form-item label="身份证人像面">
                        <el-image fit="contain" v-if="infoData.legalRepresentativeCardValidityImage01"
                                  style="width: 100px; height: 100px"
                                  :src="imageURL + infoData.legalRepresentativeCardValidityImage01"
                                  :preview-src-list="[imageURL + infoData.legalRepresentativeCardValidityImage01]">
                        </el-image>
                    </el-form-item>

                    <el-form-item label="身份证国徽面">
                        <el-image fit="contain" v-if="infoData.legalRepresentativeCardValidityImage02"
                                  style="width: 100px; height: 100px"
                                  :src="imageURL + infoData.legalRepresentativeCardValidityImage02"
                                  :preview-src-list="[imageURL + infoData.legalRepresentativeCardValidityImage02]">
                        </el-image>
                    </el-form-item>
                </div>
            </div>

            <div class="admin_page_item mt18 pr0 pb0">
                <h1 class="title">银行基本户信息</h1>
                <div class="data_detail mt24">
                    <el-form-item label="银行开户名">
                        <span class="text">{{infoData.corporateBankAccountName}}</span>
                    </el-form-item>

                    <el-form-item label="银行账号">
                        <span class="text">{{infoData.corporateBankNo}}</span>
                    </el-form-item>

                    <el-form-item label="开户银行名称">
                        <span class="text">{{infoData.corporateBankBranchName}}</span>
                    </el-form-item>

                    <el-form-item label="开户行行号">
                        <span class="text">{{infoData.corporateBankBranchNo}}</span>
                    </el-form-item>

                    <el-form-item label="开户银行所在地">
                        <span class="text">{{infoData.corporateBankBranchProvince}} {{infoData.corporateBankBranchCity}} {{infoData.corporateBankBranchArea}}</span>
                    </el-form-item>

                    <el-form-item label="证件上传" class="full">
                        <el-image fit="contain" v-if="infoData.corporateAccountCertificate"
                                  style="width: 100px; height: 100px"
                                  :src="imageURL + infoData.corporateAccountCertificate"
                                  :preview-src-list="[imageURL + infoData.corporateAccountCertificate]">
                        </el-image>
                    </el-form-item>
                </div>
            </div>

            <div class="admin_page_item mt18 pr0 pb0">
                <h1 class="title">结算账号信息</h1>
                <div class="data_detail mt24">
                    <el-form-item label="银行开户名">
                        <span class="text">{{infoData.settleBankAccountName}}</span>
                    </el-form-item>

                    <el-form-item label="银行账号">
                        <span class="text">{{infoData.settleBankNo}}</span>
                    </el-form-item>

                    <el-form-item label="开户银行名称">
                        <span class="text">{{infoData.settleBankBranchName}}</span>
                    </el-form-item>

                    <el-form-item label="开户行行号">
                        <span class="text">{{infoData.settleBankBranchNo}}</span>
                    </el-form-item>

                    <el-form-item label="开户银行所在地">
                        <span class="text">{{infoData.settleBankBranchProvince}} {{infoData.settleBankBranchCity}} {{infoData.settleBankBranchArea}}</span>
                    </el-form-item>

                    <el-form-item label="结算账号类型">
                        <span class="text" v-if="infoData.settleBankType === 1">借记卡</span>
                        <span class="text" v-if="infoData.settleBankType === 2">对公账户</span>
                    </el-form-item>
                </div>
            </div>

            <div class="admin_page_item mt18 pr0 pb0">
                <h1 class="title">店铺经营信息</h1>
                <div class="data_detail mt24">
                    <el-form-item label="店铺名称" class="full">
                        <span class="text">{{infoData.storeName}}</span>
                    </el-form-item>
                </div>
            </div>

            <div class="admin_page_item mt18">
                <h1 class="title">店铺经营类目</h1>
                <div class="data_detail mt24">
                    <el-table :data="merchantClassData" style="width: 100%">
                        <el-table-column prop="level1Name" label="一级类目" min-width="120px"></el-table-column>
                        <el-table-column prop="level2Name" label="二级类目" min-width="120px"></el-table-column>
                        <el-table-column prop="level3Name" label="三级类目" min-width="120px"></el-table-column>
                    </el-table>
                </div>
            </div>
        </el-form>

        <div style="text-align: center; margin-top: 20px">
            <el-button type="danger" @click="toExit">注销商家</el-button>
        </div>
    </div>
</template>

<script>
  import {getMerchant, getMerchantClass} from "../../../api/admin/merchant"
  import {imageURL} from '../../../utils/config'
  import {postExit} from "../../../api/public";

  export default {
    name: "adminMerchantEditFirst",
    data () {
      return {
        steps: 0,
        form: {},
        infoData: {},
        merchantClassData: [],
        isRequestLoading: false,
        imageURL: imageURL
      }
    },
    created() {
      this.$store.commit('changeScrollTop');
      this.getMerchant()
      this.getMerchantClass()
    },
    methods: {
      getMerchant () {
        this.isRequestLoading = true
        getMerchant().then(res => {
          this.isRequestLoading = false
          if (res.code === 0) {
            this.infoData = res.data
            this.infoData.legalRepresentativeCardValidityImage01 = this.infoData.legalRepresentativeCardValidityImage.split(',')[0]
            this.infoData.legalRepresentativeCardValidityImage02 = this.infoData.legalRepresentativeCardValidityImage.split(',')[1]
          }
        }).catch(() => {
          this.isRequestLoading = false
        })
      },
      getMerchantClass () {
        getMerchantClass().then(res => {
          if (res.code === 0) {
            this.merchantClassData = res.data
          }
        })
      },
      toExit () {
        this.$confirm('用户注销后将不可用，是否确认?', '提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          closeOnClickModal: false,
          type: 'warning'
        }).then(() => {
          postExit().then(res => {
            if (res.code === 0) {
              localStorage.removeItem('access_token_shop');
              this.$store.commit('changeLoginType', false);
              this.$router.replace('/login');
              this.$message({
                message: '您的申请将于1个工作日内完成，请您耐心等待。',
                type: 'success',
                showClose: true,
              });
            }
          })
        }).catch(() => {});
      },
    }
  }
</script>
